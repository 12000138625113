import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { defaultConfig, defaultTheme } from './helpers/config';

window.cookieWall = function (config) {
    
    // const colorConfig = JSON.parse(localStorage.getItem('ao_cookie_wall_theme'));

    const mergedConfig = {
        ...defaultConfig,
        ...config,
        // ...colorConfig,
    };

    const debug = mergedConfig.debug;

    delete config.debug;

    // const validator = new ConfigValidator();

    // const isValid = validator.validate(config);

    // if (config.gaSessionId === null) {
    //     if (typeof window.ga_session_id !== 'undefined') {
    //         if (window.ga_session_id.value !== undefined) {
    //             config.gaSessionId = window.ga_session_id.value;
    //         } else {
    //             config.gaSessionId = window.ga_session_id;
    //         }
    //     } else {
    //         config.gaSessionId = '';
    //     }
    // }

    // // console.log(config.gaSessionId);

    // if (isValid === false) {
    //     const errors = validator.getErrors();
    //     console.info('Merida config validation errors:'); // eslint-disable-line
    //     console.table(errors); // eslint-disable-line

    //     if (validator.getError('rootEl')) {
    //         return false;
    //     }

    //     return render(
    //         <div>
    //             Impossibile caricare il form, controlla la console per eventuali errori di
    //             configurazione...
    //         </div>,
    //         document.getElementById(config.rootEl)
    //     );
    // }

    // if (document.getElementById(config.rootEl) === null) {
    //     console.warn(
    //         `WARNING: the specified root element with id "${config.rootEl}" does not appear to exists in the DOM. Please fix it and refresh the page.`
    //     ); //eslint-disable-line

    //     return false;
    // }

    // const emitter = mitt();

    const instance = {};
    // const instance = {
    //     enableDebug() {
    //         emitter.emit('debug:enabled');
    //     },
    //     disableDebug() {
    //         emitter.emit('debug:disabled');
    //     },
    //     getConfig() {
    //         console.warn(
    //             'Attenzione: questo oggetto rappresenta la variabile di configurazione iniziale, eventuali valori modificati a runtime potrebbero non essere aggiornati!'
    //         ); // eslint-disable-line
    //         return config;
    //     },
    //     getDebug() {
    //         // FIXME: non trovo un moodo più elegante di ritornare un valore attraverso un event emitter...
    //         let exchange = {};
    //         emitter.emit('debug:status', exchange);
    //         console.info('Merida debug status:', exchange.debug); // eslint-disable-line

    //         return exchange.debug;
    //     },
    //     getRecipients() {
    //         // FIXME: non trovo un moodo più elegante di ritornare un valore attraverso un event emitter...
    //         let exchange = {};
    //         emitter.emit('get:recipients', exchange);

    //         return exchange.recipients;
    //     },
    //     addRecipient(id) {
    //         emitter.emit('add:recipient', id);
    //     },
    //     removeRecipient(id) {
    //         emitter.emit('remove:recipient', id);
    //     },
    //     resetRecipients() {
    //         emitter.emit('reset:recipients');
    //     },
    //     setRecipients(ids) {
    //         emitter.emit('set:recipients', ids);
    //     },
    //     setValues(values) {
    //         emitter.emit('set:values', values);
    //     },
    //     setGaSessionId(id) {
    //         // FIXME: verificare che questo funzioni sempre, sarebbe il caso di passare
    //         // una prop dedicata?
    //         config.gaSessionId = id;
    //         // emitter.emit('set:ga:session:id', id);
    //     },
    //     __DEBUG__showSuccessModal() {
    //         emitter.emit('debug:show:success:modal');
    //     },
    //     __DEBUG__toggleForceDayPickerOpen() {
    //         if (typeof window.__DEBUG__showMeridaDatepicker !== 'undefined') {
    //             delete window.__DEBUG__showMeridaDatepicker;
    //         } else {
    //             window.__DEBUG__showMeridaDatepicker = true;
    //         }
    //     },
    //     __DEBUG__clearFormDataCookie() {
    //         clearFormDataCookie();
    //     },
    //     __VERSION: process.env.MERIDA_VERSION
    // };

    const theme = { ...defaultTheme, ...config.theme };

    // if (config.bugsnag) {
    //     loadScript('//d2wy8f7a9ursnm.cloudfront.net/v7/bugsnag.min.js', () => {
    //         window.bugsnagClient = bugsnag({
    //             // eslint-disable-line
    //             apiKey: config.bugsnag,
    //             autoNotify: false
    //         });
    //     });
    // }

    ReactDOM.render(
        <React.StrictMode>
            <App config={mergedConfig} debug={debug} theme={theme} />
        </React.StrictMode>,
        document.getElementById('cookieWall')
    );

    if (typeof window.cookieWall === 'undefined') {
        window.cookieWall = instance;
    }

    return instance;
};
