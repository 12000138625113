import React from 'react';
import { darken, lighten /*, darken, stripUnit*/ } from 'polished';

import withTheme from './withTheme';
import { jsx } from '../helpers/styling';

export const Button = jsx('button', '');

function getCursor(props) {
    if (props.isSubmitting) {
        return 'default';
    }

    if (props.disabled) {
        return 'not-allowed';
    }

    return 'pointer';
}

const ConsentButtonComponent = (props) => {
    const { theme, isSubmitting, ...rest } = props;

    const style = `
        @keyframes animation-rotate {
            100% {
                transform: rotate(360deg);
            }
        }
        padding: 0.75em;
        color: ${theme.white};
        background-color: ${theme.primary};
        font-size: 0.85em;
        text-align: center;
        cursor: ${getCursor(props)};
        opacity: ${props.disabled || props.incomplete ? 0.65 : 1};
        border: 2px solid ${theme.primary};
        font-weight: 600;
        margin-bottom: 0.6rem;
        :hover {
            background-color: ${
                isSubmitting || props.disabled ? theme.primary : lighten(0.1, theme.primary)
            };
            border-color: ${lighten(0.1, theme.primary)};
        }
        width: 100%;
        ${isSubmitting ? loadingState(props) : ''}
    `;

    return <Button {...rest} css={style} />;
};

export const ConsentButton = withTheme(ConsentButtonComponent);

const SettingsButtonComponent = (props) => {
    const { theme, isSubmitting, ...rest } = props;

    const style = `
        @keyframes animation-rotate {
            100% {
                transform: rotate(360deg);
            }
        }
        padding: 0.75em;
        color: ${theme.text};
        background-color: ${theme.background};
        font-size: 0.85em;
        text-align: center;
        cursor: ${getCursor(props)};
        opacity: ${props.disabled || props.incomplete ? 0.65 : 1};
        border: 2px solid #5d6268;
        font-weight: 600;
        margin-bottom: 0.6rem;
        :hover {
            background-color: ${
                isSubmitting || props.disabled ? theme.primary : darken(0.1, theme.white)
            };
            color: ${lighten(0.2, theme.text)}
        }
        width: 100%;
        ${isSubmitting ? loadingState(props) : ''}
    `;

    return <Button {...rest} css={style} />;
};

export const SettingsButton = withTheme(SettingsButtonComponent);

const LinkButtonComponent = (props) => {
    const { theme, inline, isSubmitting, ...rest } = props;

    const style = `
        @keyframes animation-rotate {
            100% {
                transform: rotate(360deg);
            }
        }
        display: ${inline ? 'inline-' : ''}block;
        padding: 0rem;
        color: ${theme.link};
        cursor: ${getCursor(props)};
        border: 0;
        font-weight: 400;
        margin: 0.3rem 0;
        :hover {
            color: ${lighten(0.1, theme.link)}
        }
        background-color: transparent;
        ${isSubmitting ? loadingState(props) : ''}
    `;

    return <Button {...rest} css={style} />;
};

export const LinkButton = withTheme(LinkButtonComponent);

const ShowMyCookiesButtonComponent = (props) => {
    const { theme, isSubmitting, ...rest } = props;

    const style = `
        @keyframes animation-rotate {
            100% {
                transform: rotate(360deg);
            }
        }
        padding: 0.75em;
        color: ${theme.white};
        background-color: ${theme.primary};
        text-align: center;
        cursor: ${getCursor(props)};
        opacity: ${props.disabled || props.incomplete ? 0.65 : 1};
        border: 0;
        font-weight: 600;
        :hover {
            background-color: ${
                isSubmitting || props.disabled ? theme.primary : lighten(0.1, theme.primary)
            };
            border-color: ${lighten(0.1, theme.primary)};
        }
        border-radius:8px;
        ${isSubmitting ? loadingState(props) : ''};
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 50px;
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
        margin-left: 5px;
    `;

    return <Button {...rest} css={style} />;
};

export const ShowMyCookiesButton = withTheme(ShowMyCookiesButtonComponent);
