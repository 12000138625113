import { LAYOUT_DEFAULT, COLOR_DEFAULT, HOTEL_ID, COOKIE_BADGE } from './constants';

export const defaultConfig = {
    language: 'en',
    layout: LAYOUT_DEFAULT, // [default, compact]
    color: COLOR_DEFAULT, // [light, dark]
    hotelId: HOTEL_ID,
    cookieBadge: COOKIE_BADGE,
    debug: false,
    bugsnag: false,
    theme: {},
    onSuccess() {},
    onMount() {},
    onValidationError() {},
    onSubmitError(err) {
        console.error(err); // eslint-disable-line
    },
    onBeforeSubmit() {
        return true;
    },
};

export const defaultTheme = {
    primary: '#59B28F',
    // primary: '#26bd7e',
    secondary: '#00a3af',
    text: '#2B3139',
    text_light: '#6D7177',
    link: '#59B28F',
    white: '#fff',
    error: '#cc0033',
    background: '#fff',
};
