export const english = {
    translation: {
        checkbox: {
            necessary: 'Necessary',
            performance: 'Performance',
            targeting: 'Targeting',
            unclassified: 'Unclassified',
        },
        buttons: {
            accept_all: 'Accept all',
            reject_all: 'Reject all',
            show_details: 'Show details',
            hide_details: 'Hide details',
            save_exit: 'Save and exit',
        },
        banner_title: 'We care about your privacy',
        banner_description1: `We use cookies to give you a faster, more personalized browsing experience. By clicking on 'Accept all' you agree to the terms specified in our `,
        banner_description2: `, but if you prefer you can set your own preferences. By closing this banner or continuing to browse, you agree to the use of technical coockie necessary for the operation of the site. You can change your choices at any time  :)`,
        category: {
            analytics: {
                title: 'Analytical',
                description:
                    'These cookies allow us to count visits and to know the traffic sources of the site, so that we can measure and improve our performance. They help us know which pages are the most and least popular and see how visitors move around the site. For example, the last page visited, the number of pages visited by each person, which parts of the site are clicked on. All information collected by cookies is aggregated and therefore anonymous. If you do not allow these cookies we will not be able to guide you in finding the contents most related to your preferences.',
            },
            functional: {
                title: 'Strictly necessary',
                description:
                    "These cookies are necessary for the website to function and cannot be deactivated in our systems. They are typically only assigned in response to actions performed by you that result in a request for services, such as setting your privacy preferences, logging in or filling out forms. You have the right to block these cookies, but as a result some parts of the site will not work. Also note that these cookies do not store personal information.",
            },
            marketing: {
                title: 'Promotional',
                description:
                    "These cookies may be stored on your device via our site on behalf of our advertising partners. They can be used by these companies to build a profile of your interests and show you relevant ads on other sites as you browse. They do not directly store personal information, but are based solely on the identification of your browser and your internet device. If you do not accept these cookies, you will see less targeted advertising and you will lose the opportunity to learn about offers and promotions relating to this site that may be of interest to you.",
            },
            other: {
                title: 'Third party',
                description:
                    'Not all site technologies are proprietary, some functions are developed by our suppliers. Some of these need third-party cookies to function properly. In this case, some information about users may be shared with our partners, always anonymously and in accordance with our contractual obligations towards the partners. We do this to help our suppliers improve the effectiveness of their services and to improve your browsing experience.',
            },
        },
        cookie_details: 'Cookie details',
        elenco_cookie: 'Cookies list',
        manage_preferences: 'Manage consensus preferences',
        more_info: 'Cookie policy completa',
        privacy_center: 'Privacy Preference Center',
        privacy_center_paragraph:
            'Cookies are used on our website and in our emails to provide you with a better service and experience. Cookies are small data files that are stored by your device containing a certain amount of information. When you visit this site (or any other site) again, your device will check for a recognized cookie so that it can read the information it contains. The cookies we use allow us to recognize returning users and their information so that they do not have to enter the same information multiple times. They also allow us to analyse the traffic patterns of users who visit our website so that we can improve its usability. Here you can choose how you prefer to handle your personal information. You can change these choices at any time, and we will retain the data we collect for up to 6 months.',
        save_preferences: 'Confirm my choices',        
        logo_title: 'Cookie Preference',

        cookie_title: 'This website uses cookies',
        cookie_description:
            'This website uses cookies to improve your browsing experience. By using our website you consent to all cookies in accordance with our cookie policy.',
        read_more: 'Read more',
        unexpected_error:
            'An unexpected error occurred! Please try refreshing the page. <br/> If the problem persists, contact the site administrator.',
        reload: 'Reload',
    },
};
