export const italian = {
    translation: {
        buttons: {
            accept_all: 'Accetta tutto',
            reject_all: 'Rifiuta tutto',
            show_details: 'Gestione preferenze',
            hide_details: 'Nascondi dettagli',
            save_exit: 'Salva ed esci',
        },
        banner_title: 'Abbiamo a cuore la tua privacy',
        banner_description1: `Utilizziamo i cookie per offrirti un'esperienza di navigazione più veloce e per mostrarti annunci pubblicitari personalizzati. Cliccando su 'Accetta tutto' acconsenti a quanto specificato nella nostra `,
        banner_description2: `,ma se preferisci puoi impostare le tue preferenze. Chiudendo questo banner o continuando a navigare, accetti l’utilizzo dei coockie tecnici necessari per il funzionamento del sito. Potrai cambiare le tue scelte in qualsiasi momento :)`,
        category: {
            analytics: {
                title: 'Analitici',
                description:
                    'Questi cookie ci permettono di contare le visite e di conoscere le fonti di traffico del sito, così da poter misurare e migliorare le nostre prestazioni. Ci aiutano a sapere quali sono le pagine più e meno popolari e vedere come i visitatori si muovono sul sito. Ad esempio l’ultima pagina visitata, il numero di pagine visitate da ogni persona, quali parti del sito vengono cliccate. Tutte le informazioni raccolte dai cookie sono aggregate e quindi anonime. Se non consenti questi cookie non potremo guidarti nel trovare i contenuti più inerenti alle tue preferenze.',
            },
            functional: {
                title: 'Strettamente necessari',
                description:
                    "Questi cookie sono necessari per il funzionamento del sito Web e non possono essere disattivati nei nostri sistemi. In genere vengono assegnati solo in risposta alle azioni eseguite da te che comportano una richiesta di servizi, ad esempio l'impostazione delle preferenze sulla privacy, l'accesso o la compilazione dei moduli. Hai la facoltà di bloccare questi cookie, ma di conseguenza alcune parti del sito non funzioneranno. Considera anche che questi cookie non archiviano informazioni personali.",
            },
            marketing: {
                title: 'Promozionali',
                description:
                    "Questi cookie possono essere salvati sul tuo dispositivo tramite il nostro sito per conto dei nostri partner pubblicitari. Possono essere utilizzati da queste aziende per costruire un profilo dei tuoi interessi e mostrarti annunci pertinenti su altri siti mentre navighi. Non memorizzano direttamente informazioni personali, ma sono basati unicamente sull'individuazione del tuo browser e del tuo dispositivo internet. Se non si accettano questi cookie visualizzerai una pubblicità meno mirata e perderai l’occasione di conoscere offerte e promozioni relative a questo sito che invece potrebbero interessarti.",
            },
            other: {
                title: 'Di terze parti',
                description:
                    'Non tutte le tecnologie del sito sono proprietarie, alcune funzioni sono sviluppate dai nostri fornitori. Alcune di queste per funzionare correttamente hanno bisogno di cookie di terze parti. In questo caso alcune informazioni sugli utenti potrebbero essere condivise con i nostri partner, in modo sempre anonimo e in conformità ai nostri obblighi contrattuali nei confronti dei partner. Facciamo questo per aiutare i nostri fornitori a migliorare l’efficacia dei loro servizi e per migliorare la tua esperienza di navigazione.',
            },
            // Preferences: {
            //     title: 'Cookie di funzionalità',
            //     description:
            //         'Questi cookie consentono al sito di fornire funzionalità e personalizzazione avanzate. Possono essere impostati da noi o da provider di terze parti i cui servizi sono stati aggiunto alle nostre pagine. Se non si autorizzano questi cookie, alcuni o tutti questi servizi potrebbero non funzionare correttamente.',
            // },
        },
        cookie_details: 'Dettagli dei cookie',
        cookie_title: 'Questo sito web utilizza cookie',
        elenco_cookie: 'Elenco cookie',
        manage_preferences: 'Gestisci preferenze consenso',
        more_info: 'Cookie policy completa',
        privacy_center: 'Centro preferenze sulla privacy',
        privacy_center_paragraph:
            'I cookie vengono utilizzati sul nostro sito Web e nelle nostre e-mail per offrirti un servizio e un’esperienza migliori. I cookie sono piccoli file di dati che vengono memorizzati dal tuo dispositivo contenenti una certa quantità di informazioni. Quando visiterai di nuovo questo sito (o qualunque altro sito) il tuo dispositivo verificherà la presenza di un cookie riconosciuto, in modo da potere leggere le informazioni in esso contenute. I cookie che utilizziamo ci consentono di riconoscere gli utenti che tornano a visitare il sito e i loro dati, in modo da evitare loro di dover inserire più volte le stesse informazioni. Inoltre, ci permettono di analizzare i modelli di traffico degli utenti che visitano il nostro sito Web in modo da migliorarne l’usabilità. Qui potrai scegliere come preferisci gestire le tue informazioni personali. Potrai cambiare queste scelte in qualsiasi momento e conserveremo i dati raccolti per un massimo di 6 mesi.',
        reload: 'Ricarica',
        save_preferences: 'Conferma le mie scelte',
        unexpected_error:
            "Si è verificato un errore imprevisto! Provare ad aggiornare la pagina. <br/> Se il problema persiste contattare l'amministratore del sito.",
        logo_title: 'Preferenze Cookie',
    },
};
