import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const CookieExternalLink = ({ setShowSettingsModal }) => {
    const elementExistsInDom = document.getElementById('cw-cookiebutton-n943');

    useEffect(() => {
        elementExistsInDom !== null &&
        ReactDOM.render(
            <button style={{border:'0',background:'transparent',cursor:'pointer'}} onClick={() => setShowSettingsModal(true)}>Cookie Policy</button>,
            document.getElementById('cw-cookiebutton-n943')
        );
    }, []);

    return null;
};
export default CookieExternalLink;
