import React from 'react';
import { BiCookie } from 'react-icons/bi';
import { ShowMyCookiesButton } from './Buttons';

const CookieBadge = ({ setShowSettingsModal }) => {
    return (
        <ShowMyCookiesButton onClick={() => setShowSettingsModal(true)}>
            <BiCookie className="cw-button"></BiCookie>
        </ShowMyCookiesButton>
    );
};

export default CookieBadge;
