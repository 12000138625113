import hoistNonReactStatics from 'hoist-non-react-statics';
import { forwardRef } from 'react';
// import PropTypes from 'prop-types';

import { defaultTheme } from '../helpers/config';
import { ThemeContext } from '../helpers/contexts';

import React from 'react';

const withTheme = (WrappedComponent) => {
    const componentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    let render = (props, ref) => {
        return (
            <ThemeContext.Consumer>
                {(theme) => {
                    return <WrappedComponent theme={theme || defaultTheme} {...props} ref={ref} />;
                }}
            </ThemeContext.Consumer>
        );
    };

    let WithTheme = forwardRef(render);

    WithTheme.displayName = `WithTheme${componentName}`;

    return hoistNonReactStatics(WithTheme, WrappedComponent);
};

export default withTheme;
