import React, { lazy, Suspense, useEffect, useState } from 'react';
import './App.css';
import { getLocalStorage, loadConfig } from './helpers/api';
import './i18n/main';
import { useTranslation } from 'react-i18next';
import CookieBadge from './components/CookieBadge';
import CookieExternalLink from './components/CookieExternalLink';

const ConsentBannerLoader = lazy(() =>
    import(/* webpackChunkName: "cookie-banner" */ './components/ConsentBannerWrapper')
);

const Loader = () => {
    return (
        <div className="cw-loading-wrapper">
            <div className="cw-loading-anim">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

const App = ({ config, /*debug,*/ theme }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [remoteCookie, setRemoteCookie] = useState({});
    const [showCookieBadge, setShowCookieBadge] = useState(false);
    const [showConsentBanner, setShowConsentBanner] = useState(true);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [cookiePreferences, setCookiePreferences] = useState({});
    const [cookieVersion, setCookieVersion] = useState(0);

    const initialCookiePreferences = {
        functional: true,
        analytics: false,
        marketing: false,
        other: false,
    };

    const handleOnClickButton = () => {
        setShowConsentBanner(false);
        setShowCookieBadge(true);
        setShowSettingsModal(false);
    };
    useEffect(() => {
        config.cookieBadge ? null : setShowCookieBadge(false);
    }, [showCookieBadge]);

    // TODO: debug???

    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(config.language);

        Promise.all([fetchRemoteCookie(), loadAsyncModules()])
            .then(() => {
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        const cookieLocalStorage = getLocalStorage();

        setCookiePreferences(initialCookiePreferences);

        if (cookieLocalStorage != null) {
            handleOnClickButton();
            setCookiePreferences(cookieLocalStorage.value);
        }
    }, []);

    const fetchRemoteCookie = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const data = await loadConfig(config);
            setCookieVersion(data[0]);
            setRemoteCookie(data[1]);
            return data;
        } catch (err) {
            console.error(err); // eslint-disable-line

            setIsLoading(false);
            setError(err.message);
        }
    };

    const loadAsyncModules = async () => {
        try {
            const [{ injectGlobalStyle }] = await Promise.all([import('./helpers/theme')]);
            injectGlobalStyle({
                ...theme,
                layout: config.layout,
            });
        } catch (err) {
            return console.error(err);
        }
    };

    if (error && !isLoading) {
        console.log('errore nel cookie-wall');
        // return <div className="cw-loading-wrapper">Errore</div>;
        return '';
    }

    // if (isLoading) {
    //     return <Loader />;
    // }
    {
        setTimeout(function () {
            var a = document.createElement('script');
            var b = document.getElementsByTagName('script')[0];
            a.src =
                document.location.protocol +
                '//static.adriasonline.it/js/informativa_2.js?' +
                Math.floor(new Date().getTime() / 3000);
            a.async = true;
            a.type = 'text/javascript';
            b.parentNode.insertBefore(a, b);
        }, 1);
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.text = `var informativaConfig = {lang: '${config.language}',idHotel: '${config.hotelId}'};setTimeout(function(){var a=document.createElement('script');var b=document.getElementsByTagName('script')[0];a.src=document.location.protocol+'//static.adriasonline.it/js/informativa_2.js?'+Math.floor(new Date().getTime()/3000);a.async=true;a.type='text/javascript';b.parentNode.insertBefore(a,b)}, 1);`;
        let posizione = document.getElementById('cookieWall');
        
        posizione.appendChild(script);

        return () => {
            posizione.removeChild(script);
        };
    }, []);
    return (
        <div
            className={`cookie-wall-root ${showCookieBadge ? 'cw-badge' : 'cw-no-badge'} ${
                config.layout === 'compact' ? 'cw-compact-root' : ''
            } `}
        >
            <Suspense fallback={<Loader />}>
                <ConsentBannerLoader
                    config={config}
                    // debug={debug}
                    remoteCookie={remoteCookie}
                    theme={theme}
                    handleOnClickButton={handleOnClickButton}
                    setShowSettingsModal={setShowSettingsModal}
                    showSettingsModal={showSettingsModal}
                    showConsentBanner={showConsentBanner}
                    cookiePreferences={cookiePreferences}
                    setCookiePreferences={setCookiePreferences}
                    cookieVersion={cookieVersion}
                />
                {showCookieBadge && <CookieBadge setShowSettingsModal={setShowSettingsModal} />}
            </Suspense>
            <CookieExternalLink setShowSettingsModal={setShowSettingsModal} />
        </div>
    );
};

export default App;
