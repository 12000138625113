// chiamata api on load
const checkStatus = (res) => {
    // console.warn(res);
    if (res.ok) {
        return res;
    } else {
        const error = new Error(res.statusText);
        error.name = 'HTTPError';
        error.response = res;
        throw error;
        // return Promise.reject(error);
    }
};

const makeCall = (url, options = {}) => {
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(checkStatus)
            .then((res) => res.json())
            .then((data) => resolve(data))
            .catch((err) => {
                // console.warn(err);
                reject(err);
            });
    });
};

const formatConfig = (response /*language*/) => {
    const data = { ...response };

    return Object.values(data);
};

const getConfigRequestUrl = () => {
    // const url = `https://raw.githubusercontent.com/tommaso-adrias/demo/master/cookie-wall/api.json`;
    const dominio = window.location.hostname;

    if (dominio === 'localhost' || dominio ==='stag.adriasonline.it' || dominio ==='staging.adriasonline.it') {
        return `${process.env.REACT_APP_AO_COOKIES}/Cookies/Dominio/www.adriasonline.it`;
    }
    const url = `${process.env.REACT_APP_AO_COOKIES}/Cookies/Dominio/${dominio}`;

    return url;
};
export const loadConfig = (localConfig) => {
    const clientLocalStorage = getLocalStorage();

    // setLocalStorage('test localstorage');

    const requestUrl = getConfigRequestUrl(localConfig);

    return new Promise((resolve, reject) => {
        const xhr = makeCall(requestUrl);

        // resolve(formatConfig(dummyResponse, localConfig.language));
        xhr.then((res) => {
            if (clientLocalStorage != null) {
                checkCookieVersion(clientLocalStorage.version, res.version);
            }
            resolve(formatConfig(res, localConfig.language));
        }).catch((err) => reject(err));
    });
};

// gestione localStorage
export const setLocalStorage = (value, version) => {
    // const ttl = 15000; // 15 secondi in millisecondi
    const ttl = 15552000000; // 180 giorni in millisecondi

    const item = {
        value: value,
        expiry: Date.now() + ttl,
        version: version,
    };
    localStorage.setItem('ao_cookie_wall', JSON.stringify(item));
};

const checkCookieVersion = (localVersion, apiVersion) => {
    if (apiVersion > localVersion) {
        localStorage.removeItem('ao_cookie_wall');
    }
};

export const getLocalStorage = () => {
    const itemStr = localStorage.getItem('ao_cookie_wall');

    // console.log(itemStr);

    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);

    if (Date.now() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem('ao_cookie_wall');
        return null;
    }
    return item;
};
