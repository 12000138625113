export const MESSAGE_VALIDATION_ERROR = 'validation_error';
export const MESSAGE_SUBMIT_ERROR = 'submit_error';
export const MESSAGE_SUBMIT_SUCCESS = 'submit_success';

export const LAYOUT_DEFAULT = 'default';
// export const LAYOUT_COMPACT = 'compact';

export const COLOR_DEFAULT = 'light';

export const FORM_NAME = 'cookie-wall-form';

export const HOTEL_ID = '';

export const COOKIE_BADGE = true;

export const cookieCategories = ['Analytics', 'Functional', 'Marketing', 'Other', 'Preferences'];
